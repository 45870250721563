import { createReducer, on } from '@ngrx/store';

import { clearStore } from '../../../../store/common/clear-store.actions';
import { editTripRootDestroy, editTripSuccess, loadEditTripDataSuccess, openEditTripFlow } from './edit-trip.actions';
import { GetCharterTripResponse } from '@rootTypes/api';

export interface EditTripState {
  hasUnsavedChanges: boolean;
  charterTrip?: GetCharterTripResponse;
}

const createInitialState = (): EditTripState => ({ hasUnsavedChanges: false });

export const editTripReducer = createReducer(
  createInitialState(),
  on(clearStore, openEditTripFlow, editTripRootDestroy, createInitialState),
  on(loadEditTripDataSuccess, (state, { data }): EditTripState => {
    return {
      hasUnsavedChanges: true,
      charterTrip: data.charterTrip,
    };
  }),
  on(editTripSuccess, (): EditTripState => {
    return { hasUnsavedChanges: false };
  }),
);
