import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { delay } from 'rxjs/operators';

import { BaseApiService } from './base-api.service';
import * as fromApi from '@rootTypes/api';
import { getCharterTripMockResponse } from '@rootTypes/api/get-charter-trip/mock-response';
import { getCharterDirectionsMockResponse } from '@rootTypes/api/get-charter-directions/mock-response';
import { GetCharterCatalogRequest, GetCharterCatalogResponse } from '@rootTypes/api';
import { CharterCatalogApiService } from './charter-catalog-api.service';
import { getCharterTripPurposeMockResponse } from '@rootTypes/api/get-charter-trip-purpose/mock-response';
import { getCharterTripItineraryMockResponse } from '@rootTypes/api/get-charter-trip-itinerary/mock-response';
import { validateTripItineraryRegionDetectedMock } from '@rootTypes/api/validate-charter-trip-itinerary/mock-response';

@Injectable()
export class MockApiService {
  private portalEndpoint = 'admin';

  constructor(
    private baseApi: BaseApiService,
    private catalogApi: CharterCatalogApiService,
  ) {}

  public createCharterCustomer(
    request: fromApi.CreateCharterCustomerRequest,
  ): Observable<fromApi.CreateCharterCustomerResponse> {
    return of({ customerId: 'newCustomerMockId' }).pipe(delay(1000));
  }

  public createCharterTrip(request: fromApi.CreateCharterTripRequest): Observable<fromApi.CreateCharterTripResponse> {
    return of({ tripId: 'tripMockId' }).pipe(delay(1000));
  }

  public entityFilter(request: fromApi.EntityFilterRequest): Observable<fromApi.EntityFilterResponse> {
    return this.baseApi.post('entityFilter', { ...request });
  }

  public entitySearch(request: fromApi.EntitySearchRequest): Observable<fromApi.EntitySearchResponse> {
    return this.baseApi.post('entitySearch', { ...request });
  }

  public findCharterCatalog(
    request: fromApi.FindCharterCatalogRequest,
  ): Observable<fromApi.FindCharterCatalogResponse> {
    return this.baseApi.post('findCharterCatalog', { ...request });
  }

  public getAccount(): Observable<any> {
    return this.baseApi.post('getAccount', undefined, this.portalEndpoint);
  }

  public getCharterConfig(request: fromApi.GetCharterConfigRequest): Observable<fromApi.GetCharterConfigResponse> {
    return this.baseApi.post('getCharterConfig', { ...request });
  }

  public getCharterContract(
    request: fromApi.GetCharterContractRequest,
  ): Observable<fromApi.GetCharterContractResponse> {
    return this.baseApi.post('getCharterContract', { ...request });
  }

  public getCharterDirections(
    request: fromApi.GetCharterDirectionsRequest,
  ): Observable<fromApi.GetCharterDirectionsResponse> {
    return of(getCharterDirectionsMockResponse).pipe(delay(1000));
  }

  public getCharterRide(request: fromApi.GetCharterRideRequest): Observable<fromApi.GetCharterRideResponse> {
    return this.baseApi.post('getCharterRide', { ...request });
  }

  public getCharterTrip(request: fromApi.GetCharterTripRequest): Observable<fromApi.GetCharterTripResponse> {
    return of(getCharterTripMockResponse).pipe(delay(1000));
  }

  public getCharterTripItinerary(
    request: fromApi.GetCharterTripItineraryRequest,
  ): Observable<fromApi.GetCharterTripItineraryResponse> {
    return of(getCharterTripItineraryMockResponse).pipe(delay(1000));
  }

  public getCharterTripPurpose(): Observable<fromApi.GetCharterTripPurposeResponse> {
    return of(getCharterTripPurposeMockResponse).pipe(delay(1000));
  }

  public getCharterCatalog(request: GetCharterCatalogRequest): Observable<GetCharterCatalogResponse> {
    return from(this.catalogApi.getCharterCatalog(request));
  }

  public getCharterTripQuote(
    request: fromApi.GetCharterTripQuoteRequest,
  ): Observable<fromApi.GetCharterTripQuoteResponse> {
    return this.baseApi.post('getCharterTripQuote', { ...request });
  }

  public getDistrict(request: fromApi.GetDistrictRequest): Observable<fromApi.GetDistrictResponse> {
    return this.baseApi.post('getDistrict', request, this.portalEndpoint);
  }

  public getSchoolEmployee(request: fromApi.GetSchoolEmployeeRequest): Observable<fromApi.GetSchoolEmployeeResponse> {
    return this.baseApi.post('getSchoolEmployee', { ...request });
  }

  public updateCharterTripDetails(
    request: fromApi.UpdateCharterTripRequest,
  ): Observable<fromApi.UpdateCharterTripResponse> {
    return this.baseApi.post('updateCharterTripDetails', { ...request });
  }

  public updateCharterTripQuote(
    request: fromApi.UpdateCharterTripQuoteRequest,
  ): Observable<fromApi.UpdateCharterTripQuoteResponse> {
    return this.baseApi.post('updateCharterTripQuote', { ...request });
  }

  public validateCharterTripItinerary(
    request: fromApi.ValidateCharterTripItineraryRequest,
  ): Observable<fromApi.ValidateCharterTripItineraryResponse> {
    return of(validateTripItineraryRegionDetectedMock).pipe(delay(1000));
  }
}
