import { combineReducers } from '@ngrx/store';

import { TripEditorStopsState } from './trip-editor-stops/types';
import { tripEditorStopsReducer } from './trip-editor-stops/trip-editor-stops.reducer';
import { tripEditorQuoteReducer } from './trip-editor-quote/trip-editor-quote.reducer';
import {
  tripEditorBookingContactReducer,
  TripEditorBookingContactState,
} from './trip-editor-booking-contact/trip-editor-booking-contact.reducer';
import {
  tripEditorItineraryReducer,
  TripEditorItineraryState,
} from './trip-editor-itinerary/trip-editor-itinerary.reducer';
import { tripEditorIdsReducer, TripEditorIdsState } from './trip-editor-ids/trip-editor-ids.reducer';
import {
  tripEditorContractReducer,
  TripEditorContractState,
} from './trip-editor-contract/trip-editor-contract.reducer';
import { tripEditorPaymentReducer, TripEditorPaymentState } from './trip-editor-payment/trip-editor-payment.reducer';
import { tripEditorCustomerReducer, TripCustomerState } from './trip-editor-customer/trip-editor-customer.reducer';
import { TripEditorQuoteState } from './trip-editor-quote/types';
import { editTripReducer, EditTripState } from './edit-trip/edit-trip.reducer';

export interface TripEditorState {
  ids: TripEditorIdsState;
  stops: TripEditorStopsState;
  quote: TripEditorQuoteState;
  customer: TripCustomerState;
  bookingContact: TripEditorBookingContactState;
  itinerary: TripEditorItineraryState;
  contract: TripEditorContractState;
  payment: TripEditorPaymentState;
  editTripData: EditTripState;
}

export const tripEditorFeatureReducer = combineReducers<TripEditorState>({
  ids: tripEditorIdsReducer,
  stops: tripEditorStopsReducer,
  quote: tripEditorQuoteReducer,
  customer: tripEditorCustomerReducer,
  bookingContact: tripEditorBookingContactReducer,
  itinerary: tripEditorItineraryReducer,
  contract: tripEditorContractReducer,
  payment: tripEditorPaymentReducer,
  editTripData: editTripReducer,
});
