import { createAction, props } from '@ngrx/store';
import { EditTripData } from './edit-trip-data';
import { GetCharterTripResponse } from '@rootTypes/api';

export const openEditTripFlow = createAction('[TripDetails] Open edit trip flow');

export const loadEditTripDataSuccess = createAction(
  '[EditTripRoot] Load edit trip data success',
  props<{ data: EditTripData }>(),
);

export const editTripRootDestroy = createAction('[EditTripRoot] Destroy');

export const initEditTripItinerary = createAction('[EditTripItinerary] Init edit trip itinerary');

export const editTripItinerarySaveStateToStorage = createAction(`[EditTripItinerary] Save state to storage`);

export const initEditTripReview = createAction('[EditTripReview] Init edit trip review');

export const initEditTripReservation = createAction(
  '[EditTripReservation] Init edit trip reservation',
  props<{ charterTrip: GetCharterTripResponse }>(),
);

export const editTripItineraryChanged = createAction('[EditTripItinerary] Trip itinerary changed');

export const editTripSuccess = createAction(`[EditTripSuccess]: Trip updated successfully`);

export const unableToFindTripQuoteInSessionStorageForEditTrip = createAction(
  '[TripEditorQuoteEffects] Unable to find TripEditorQuoteState in sessionStorage for edit trip',
);
