import { createAction, props } from '@ngrx/store';

import { TripQuotePriceCorrectors, VehicleCatalog, VehicleCatalogSelected, WpError } from '@rootTypes';
import { FindCharterCatalogResponse, GetCharterTripQuoteRequest, GetCharterTripQuoteResponse } from '@rootTypes/api';
import { TripEditorQuoteState } from './types';

const vehiclesComponents = '[TripEditorVehicles]:';
export const addVehicleToTrip = createAction(
  `${vehiclesComponents} Add vehicle to trip`,
  props<{ selection: VehicleCatalogSelected }>(),
);
export const removeVehicleFromTrip = createAction(
  `${vehiclesComponents} Remove vehicle from trip`,
  props<{ catalogId: string }>(),
);
export const vehicleCountChanged = createAction(
  `${vehiclesComponents} Vehicle count changed`,
  props<{ selection: VehicleCatalogSelected }>(),
);

const quoteEffects = '[TripEditorQuoteEffects]:';
export const addVehicleToTripValid = createAction(
  `${quoteEffects} Add vehicle to trip valid`,
  props<{ selection: VehicleCatalogSelected }>(),
);
export const addVehicleToTripInvalid = createAction(
  `${quoteEffects} Add vehicle to trip invalid`,
  props<{ catalog: VehicleCatalog }>(),
);
export const tripEditorQuoteFindCatalogsRequested = createAction(
  `${quoteEffects} Find charter catalog requested`,
  props<{
    tripItineraryId: string;
    tripId?: string;
  }>(),
);
export const tripEditorQuoteFindCatalogsSuccess = createAction(
  `${quoteEffects} Find charter catalog success`,
  props<{
    response: FindCharterCatalogResponse;
    tripItineraryId: string;
  }>(),
);
export const tripEditorQuoteFindCatalogsFailed = createAction(
  `${quoteEffects} Find charter catalog failed`,
  props<{ error: WpError }>(),
);
export const tripEditorQuoteInitStateFromStorage = createAction(
  `${quoteEffects} Initialize trip quote state from storage`,
  props<{ state: TripEditorQuoteState }>(),
);
export const tripEditorQuoteRemoveStateFromStorage = createAction(`${quoteEffects} Remove trip quote from storage`);
export const getTripQuoteRequested = createAction(
  `${quoteEffects} Get trip quote requested`,
  props<{ request: GetCharterTripQuoteRequest }>(),
);
export const getTripQuoteSuccess = createAction(
  `${quoteEffects} Get trip quote success`,
  props<{ tripQuote: GetCharterTripQuoteResponse; tripItineraryId: string }>(),
);
export const getTripQuoteFailed = createAction(`${quoteEffects} Get trip quote failed`, props<{ error: WpError }>());

const reservationComponent = '[TripEditorReservation]:';
export const tripEditorReservationEditPriceRequested = createAction(
  `${reservationComponent}: Edit price requested`,
  props<{ priceCorrectors: TripQuotePriceCorrectors }>(),
);
export const tripEditorReservationEditPriceSuccess = createAction(
  `${reservationComponent}: Edit price success`,
  props<{ tripQuote: GetCharterTripQuoteResponse; tripItineraryId: string }>(),
);
export const tripEditorReservationEditPriceFailed = createAction(
  `${reservationComponent}: Edit price failed`,
  props<{ error: WpError }>(),
);
