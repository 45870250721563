import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { ApiService } from '../../../../core/services';
import * as fromActions from './trip-editor-booking-contact.actions';
import * as fromSelectors from './trip-editor-booking-contact.selectors';
import { concatLatestFrom } from '@ngrx/operators';

@Injectable()
export class TripEditorBookingContactEffects {
  public loadContactOnInit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.initBookingContactStateForCreateTrip),
      filter(({ signedInSchoolEmployeeId }) => !!signedInSchoolEmployeeId),
      map(() => fromActions.loadBookingContactDetailsRequested()),
    );
  });

  public contactSelected$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.bookingContactSelected),
      map(() => fromActions.loadBookingContactDetailsRequested()),
    );
  });

  public loadContactDetailsRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.loadBookingContactDetailsRequested),
      concatLatestFrom(() => this.store.select(fromSelectors.selectBookingContactSelection)),
      mergeMap(([, value]) => {
        return this.api.getSchoolEmployee({ schoolEmployeeId: value.entityId }).pipe(
          map((res) => {
            return fromActions.loadBookingContactDetailsSuccess({ details: res.schoolEmployee });
          }),
          catchError((originalError) => {
            return of(
              fromActions.loadBookingContactDetailsFailed({
                error: {
                  text: 'Failed to load contact details',
                  originalError,
                },
              }),
            );
          }),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store,
    private api: ApiService,
  ) {}
}
