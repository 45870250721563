import { CharterRoute, NavigationRequest, RouterStateUrl } from '../types';
import { QueryParamsHandling } from '@angular/router';
import { tripDetailsPath } from './trip-details-path';
import { RouterService } from '../router-service';
import { Injectable } from '@angular/core';

export interface TripDetailsRouteData {
  tripId: string;
}

export const tripDetailsRoute: CharterRoute<TripDetailsRouteData> = {
  path: '',
  isOnRoute(state: RouterStateUrl): boolean {
    return state.url.startsWith(`/${tripDetailsPath}`);
  },
  parse(state: RouterStateUrl): TripDetailsRouteData {
    return {
      tripId: state.params.tripId,
    };
  },
  request(
    data?: TripDetailsRouteData,
    queryParamStrategy?: QueryParamsHandling,
    replaceUrl?: boolean,
  ): NavigationRequest {
    return {
      path: [`/${tripDetailsPath}/${data.tripId}`],
      extras: { queryParamsHandling: queryParamStrategy, replaceUrl: replaceUrl || false },
    };
  },
  getHref(data?: TripDetailsRouteData): string {
    return `/${tripDetailsPath}/${data.tripId}`;
  },
};

@Injectable({ providedIn: 'root' })
export class TripDetailsRouterService extends RouterService<TripDetailsRouteData> {
  route = tripDetailsRoute;
}
