import { Pipe, PipeTransform } from '@angular/core';
import { UTCTime } from '@rootTypes/utils/common/date';

@Pipe({
  name: 'utcStampToLocalDisplayDateTime',
})
export class UtcStampToLocalDisplayDateTimePipe implements PipeTransform {
  transform(regularTimestamp?: number, pattern = 'ddd MMM DD, YYYY - h:mm A z'): string {
    if (regularTimestamp) {
      return new UTCTime(regularTimestamp).formatTo(pattern);
    }
    return '--';
  }
}
