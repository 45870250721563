import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'c-trip-display-id',
  templateUrl: './trip-display-id.component.html',
  styleUrl: './trip-display-id.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TripDisplayIdComponent {
  @Input() public id: string;
}
