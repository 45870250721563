import { createReducer, on } from '@ngrx/store';

import { clearStore } from '../../../../store/common/clear-store.actions';
import * as fromActions from './trip-editor-booking-contact.actions';
import { EntityState, PortalEntity, PortalEntityType, SchoolEmployee, TripContactType } from '@rootTypes';
import { initEditTripReservation } from '../edit-trip/edit-trip.actions';

export interface TripEditorBookingContactState {
  selection?: PortalEntity;
  contactDetails: EntityState<SchoolEmployee>;
  isChangeContactAllowed: boolean;
}

const createInitialState = (): TripEditorBookingContactState => {
  return {
    contactDetails: { isLoading: false },
    isChangeContactAllowed: true,
  };
};
export const tripEditorBookingContactReducer = createReducer(
  createInitialState(),
  on(clearStore, fromActions.destroyBookingContactState, fromActions.bookingContactRemoved, createInitialState),
  on(
    fromActions.initBookingContactStateForCreateTrip,
    (state, { signedInSchoolEmployeeId }): TripEditorBookingContactState => {
      if (signedInSchoolEmployeeId) {
        return {
          selection: {
            entityId: signedInSchoolEmployeeId,
            type: PortalEntityType.SCHOOL_EMPLOYEE,
          },
          contactDetails: { isLoading: false },
          isChangeContactAllowed: false,
        };
      }
      return {
        contactDetails: { isLoading: false },
        isChangeContactAllowed: true,
      };
    },
  ),
  on(initEditTripReservation, (state, { charterTrip }): TripEditorBookingContactState => {
    const bookingContact = charterTrip.contacts.find((contact) => contact.contactType === TripContactType.CUSTOMER);
    return {
      selection: {
        entityId: charterTrip.customerId,
        type: PortalEntityType.SCHOOL_EMPLOYEE,
        label: `${bookingContact.firstName} ${bookingContact.lastName}`,
      },
      contactDetails: {
        isLoading: false,
        entity: {
          id: charterTrip.customerId,
          firstName: bookingContact.firstName,
          lastName: bookingContact.lastName,
          email: bookingContact.email,
          phoneNumber: bookingContact.phoneNumber,
          districtId: '',
          status: null,
          organizationName: charterTrip.organizationName,
        },
      },
      isChangeContactAllowed: false,
    };
  }),
  on(fromActions.bookingContactSelected, (state, { value }): TripEditorBookingContactState => {
    return {
      ...state,
      selection: value,
      contactDetails: { isLoading: false },
    };
  }),
  on(fromActions.loadBookingContactDetailsRequested, (state): TripEditorBookingContactState => {
    return {
      ...state,
      contactDetails: { isLoading: true },
    };
  }),
  on(fromActions.loadBookingContactDetailsSuccess, (state, { details }): TripEditorBookingContactState => {
    return {
      ...state,
      contactDetails: {
        isLoading: false,
        entity: details,
      },
    };
  }),
  on(fromActions.loadBookingContactDetailsFailed, (state, { error }): TripEditorBookingContactState => {
    return {
      ...state,
      contactDetails: {
        isLoading: false,
        error,
      },
    };
  }),
  on(
    fromActions.newBookingContactCreated,
    fromActions.bookingContactUpdated,
    (state, { contact }): TripEditorBookingContactState => {
      return {
        ...state,
        selection: {
          type: PortalEntityType.SCHOOL_EMPLOYEE,
          entityId: contact.id,
          label: `${contact.firstName} ${contact.lastName}`,
        },
        contactDetails: {
          isLoading: false,
          entity: contact,
        },
      };
    },
  ),
);
