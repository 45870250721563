import { createAction, props } from '@ngrx/store';
import { PortalEntity, SchoolEmployee, WpError } from '@rootTypes';

const component = '[TripEditorBookingContact]:';
export const initBookingContactStateForCreateTrip = createAction(
  `${component} Initialize booking contact state for create trip`,
  props<{ signedInSchoolEmployeeId?: string }>(),
);
export const destroyBookingContactState = createAction(`${component} Destroy`);
export const bookingContactSelected = createAction(`${component} Contact selected`, props<{ value: PortalEntity }>());
export const bookingContactRemoved = createAction(`${component} Contact removed`);
export const newBookingContactCreated = createAction(
  `${component} New booking contact created`,
  props<{ contact: SchoolEmployee }>(),
);
export const bookingContactUpdated = createAction(
  `${component} Booking contact updated`,
  props<{ contact: SchoolEmployee }>(),
);

const effects = '[TripEditorBookingContactEffects]:';
export const loadBookingContactDetailsRequested = createAction(`${effects} Load contact details requested`);
export const loadBookingContactDetailsSuccess = createAction(
  `${effects} Load contact details success`,
  props<{ details: SchoolEmployee }>(),
);
export const loadBookingContactDetailsFailed = createAction(
  `${effects} Load contact details failed`,
  props<{ error: WpError }>(),
);
