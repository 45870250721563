import { createSelector } from '@ngrx/store';
import { selectTripEditorState } from '../trip-editor-feature.selector';
import { TripEditorLegName } from '../trip-editor-stops/types';
import { TripType } from '@rootTypes';

const selectTripEditorItineraryState = createSelector(selectTripEditorState, (state) => state.itinerary);

export const selectTripEditorItineraryLoading = createSelector(
  selectTripEditorItineraryState,
  (state) => state.isLoading,
);
export const selectTripEditorItinerary = createSelector(selectTripEditorItineraryState, (state) => state.data);
export const selectTripEditorItineraryError = createSelector(selectTripEditorItineraryState, (state) => state.error);

export const selectIsTripEditorItineraryReturnStops = createSelector(selectTripEditorItinerary, (state) => {
  return state?.return?.stops.length > 0;
});

const selectTripEditorItineraryLeg = (legName: TripEditorLegName) => {
  return createSelector(selectTripEditorItinerary, (state) => {
    if (state) {
      return state[legName];
    }
    return undefined;
  });
};
export const selectTripEditorItineraryStops = (legName: TripEditorLegName) => {
  return createSelector(selectTripEditorItineraryLeg(legName), (state) => {
    return state?.stops;
  });
};
export const selectTripEditorItineraryPolyline = (legName: TripEditorLegName) => {
  return createSelector(selectTripEditorItineraryLeg(legName), (state) => {
    return state?.summary?.polyline;
  });
};

export const selectTripEditorRegionLabel = createSelector(selectTripEditorItinerary, (state) => state?.regionLabel);

export const selectTripEditItineraryIsRoundTrip = createSelector(
  selectTripEditorItinerary,
  (state) => state?.tripType === TripType.ROUND_TRIP,
);
