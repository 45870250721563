import { createReducer, on } from '@ngrx/store';

import * as fromActions from './trip-editor-payment.actions';
import { PaymentMethod, PaymentOption } from '@rootTypes';

export interface TripEditorPaymentState {
  paymentOption?: PaymentOption;
  paymentMethod?: PaymentMethod;
  isBillNowDisabled?: boolean;
  isBillLaterDisabled?: boolean;
}

const createInitialTripEditorPaymentState = (): TripEditorPaymentState => {
  return {};
};

export const tripEditorPaymentReducer = createReducer(
  createInitialTripEditorPaymentState(),
  on(fromActions.destroyTripEditorReservationPayment, createInitialTripEditorPaymentState),
  on(
    fromActions.initTripEditorReservationPaymentFromContract,
    (state, { contract, isEditTripMode }): TripEditorPaymentState => {
      if (isEditTripMode) {
        return {
          paymentOption: contract.isGeneralContract ? PaymentOption.PAY_NOW : PaymentOption.PAY_LATER,
          isBillNowDisabled: true,
          isBillLaterDisabled: true,
        };
      }
      if (contract.isGeneralContract) {
        return {
          paymentOption: PaymentOption.PAY_NOW,
          isBillNowDisabled: false,
          isBillLaterDisabled: true,
        };
      }
      return {
        paymentOption: PaymentOption.PAY_LATER,
        isBillNowDisabled: !contract.paymentOptions.includes(PaymentOption.PAY_NOW),
        isBillLaterDisabled: false,
      };
    },
  ),
  on(fromActions.tripEditorReservationPaymentOptionChanged, (state, { paymentOption }): TripEditorPaymentState => {
    return {
      ...state,
      paymentOption,
      paymentMethod: undefined,
    };
  }),
  on(fromActions.tripEditorReservationPaymentMethodChanged, (state, { paymentMethod }): TripEditorPaymentState => {
    return {
      ...state,
      paymentMethod,
    };
  }),
);
