import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../../core/services';
import {
  getCharterCatalogFailed,
  getCharterCatalogRequested,
  getCharterCatalogSuccess,
  getCharterRideFailed,
  getCharterRideRequested,
  getCharterRideSuccess,
  getCharterTripFailed,
  getCharterTripItineraryFailed,
  getCharterTripItineraryRequested,
  getCharterTripItinerarySuccess,
  getCharterTripRequested,
  getCharterTripSuccess,
  getContractFailed,
  getContractRequested,
  getContractSuccess,
  getDriverFailed,
  getDriverRequested,
  getDriverSuccess,
  getTripInvoiceFailed,
  getTripInvoiceRequested,
  getTripInvoiceSuccess,
  getVehicleFailed,
  getVehicleRequested,
  getVehicleSuccess,
  updateCharterTripDetailsFailed,
  updateCharterTripDetailsRequested,
  updateCharterTripDetailsSuccess,
  updateTripQuoteFailed,
  updateTripQuoteRequested,
  updateTripQuoteSuccess,
} from './actions';
import { firstValueFrom, of, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { PortalEntityType, WpError } from '@rootTypes';
import { Store } from '@ngrx/store';
import { dbRideDetailsToAssignedDriverId } from '@rootTypes/utils/ride-details/db-ride-details-to-assigned-driver-id';
import { CancelTripFormValue } from '../../features/trip-details/models/cancel-trip-form';
import { CancelCharterTripRequest, GetCharterTripResponse } from '@rootTypes/api';
import { PopupService } from '../../core/popup/popup.service';
import { SnackbarService } from '../../core/snackbar/snackbar.service';
import { getPortalEntityRequestedIfAbsent } from '../entities-data/actions';
import { TripDetailsRouterService } from '../../routes/trip-details/trip-details.router-service';

@Injectable()
export class TripDataEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store,
    private snackbar: SnackbarService,
    private popup: PopupService,
    private tripDetailsRouteService: TripDetailsRouterService,
  ) {}

  public getCharterTripRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCharterTripRequested),
      mergeMap(({ tripId, options }) => {
        return this.api.getCharterTrip({ tripId }).pipe(
          map((trip) => {
            if (options?.withCatalogs) {
              (trip.catalogs || []).forEach((c) =>
                this.store.dispatch(getCharterCatalogRequested({ catalogId: c.catalogId })),
              );
            }
            if (options?.withItinerary && trip.tripItineraryId) {
              this.store.dispatch(
                getCharterTripItineraryRequested({
                  charterTripItineraryId: trip.tripItineraryId,
                  options: {
                    withContract: !!options.withContract,
                    withDistrictName: !!options.withDistrictName,
                    withCampusName: !!options.withCampusName,
                  },
                }),
              );
            }
            if (options?.withRides) {
              if (trip?.rides?.outbound?.length) {
                trip.rides.outbound.forEach(({ charterRideId }) => {
                  this.store.dispatch(
                    getCharterRideRequested({ charterRideId, options: { withDriverAndVehicle: true } }),
                  );
                });
              }
              if (trip?.rides?.return?.length) {
                trip.rides.return.forEach(({ charterRideId }) => {
                  this.store.dispatch(getCharterRideRequested({ charterRideId }));
                });
              }
            }
            if (options?.withInvoice) {
              this.store.dispatch(getTripInvoiceRequested({ request: { tripId: trip.tripId } }));
            }
            return getCharterTripSuccess({ trip });
          }),
          catchError((originalError) => {
            const error: WpError = {
              originalError,
              text: 'Failed to load charter trip',
            };
            return of(getCharterTripFailed({ tripId, error }));
          }),
        );
      }),
    );
  });

  public getCharterRideRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCharterRideRequested),
      mergeMap(({ charterRideId, options }) => {
        return this.api.getCharterRide({ charterRideId }).pipe(
          map((charterRide) => {
            if (options?.withDriverAndVehicle) {
              const vehicleId = charterRide.assignedVehicle?.vehicleId;
              const driverId = dbRideDetailsToAssignedDriverId(charterRide);
              if (vehicleId) {
                this.store.dispatch(getVehicleRequested({ vehicleId }));
              }
              if (driverId) {
                this.store.dispatch(getDriverRequested({ driverId }));
              }
            }
            return getCharterRideSuccess({ charterRide });
          }),
          catchError((originalError) => {
            const error: WpError = {
              originalError,
              text: 'Failed to load charter ride',
            };
            return of(getCharterRideFailed({ charterRideId, error }));
          }),
        );
      }),
    );
  });

  public getCharterTripItineraryRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCharterTripItineraryRequested),
      mergeMap(({ charterTripItineraryId, options }) => {
        return this.api.getCharterTripItinerary({ tripItineraryId: charterTripItineraryId }).pipe(
          map((charterTripItinerary) => {
            if (options?.withContract && charterTripItinerary?.contractId) {
              this.store.dispatch(getContractRequested({ request: { contractId: charterTripItinerary.contractId } }));
            }
            if (options?.withDistrictName && charterTripItinerary?.districtId) {
              this.store.dispatch(
                getPortalEntityRequestedIfAbsent({
                  entityId: charterTripItinerary.districtId,
                  entityType: PortalEntityType.DISTRICT,
                }),
              );
            }
            if (options?.withCampusName && charterTripItinerary?.campusId) {
              this.store.dispatch(
                getPortalEntityRequestedIfAbsent({
                  entityId: charterTripItinerary.campusId,
                  entityType: PortalEntityType.CAMPUS,
                }),
              );
            }
            return getCharterTripItinerarySuccess({ charterTripItinerary });
          }),
          catchError((originalError) => {
            const error: WpError = {
              originalError,
              text: 'Failed to load charter trip itinerary',
            };
            return of(getCharterTripItineraryFailed({ charterTripItineraryId, error }));
          }),
        );
      }),
    );
  });

  public getCharterCatalogRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCharterCatalogRequested),
      mergeMap(({ catalogId }) => {
        return this.api.getCharterCatalog({ catalogId }).pipe(
          map((catalog) => getCharterCatalogSuccess({ catalog, catalogId })),
          catchError((originalError) => {
            const error: WpError = {
              originalError,
              text: 'Failed to load charter catalog',
            };
            return of(getCharterCatalogFailed({ catalogId, error }));
          }),
        );
      }),
    );
  });

  public getDriverRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getDriverRequested),
      mergeMap(({ driverId }) => {
        return this.api.getDriver({ driverId }).pipe(
          map((resp) => {
            return getDriverSuccess({ driverId, driver: resp.driver });
          }),
          catchError((originalError) => {
            const error: WpError = {
              originalError,
              text: 'Failed to load driver',
            };
            return of(getDriverFailed({ driverId, error }));
          }),
        );
      }),
    );
  });

  public getVehicleRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getVehicleRequested),
      mergeMap(({ vehicleId }) => {
        return this.api.getVehicle({ vehicleId }).pipe(
          map((resp) => getVehicleSuccess({ vehicleId, vehicle: resp.vehicle })),
          catchError((originalError) => {
            const error = {
              originalError,
              text: 'Failed to load vehicle',
            };
            return of(getVehicleFailed({ vehicleId, error }));
          }),
        );
      }),
    );
  });

  public getTripInvoiceRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getTripInvoiceRequested),
      mergeMap(({ request }) => {
        return this.api.getCharterTripInvoice(request).pipe(
          map((resp) => getTripInvoiceSuccess({ tripId: request.tripId, invoice: resp })),
          catchError((originalError) => {
            const error: WpError = {
              originalError,
              text: 'Failed to load trip invoice',
            };
            return of(getTripInvoiceFailed({ tripId: request.tripId, error }));
          }),
        );
      }),
    );
  });

  public getContractRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getContractRequested),
      mergeMap(({ request }) => {
        return this.api.getCharterContract(request).pipe(
          map((resp) => getContractSuccess({ contractId: request.contractId, contract: resp })),
          catchError((originalError) => {
            const error: WpError = {
              originalError,
              text: 'Failed to load contract',
            };
            return of(getContractFailed({ contractId: request.contractId, error }));
          }),
        );
      }),
    );
  });

  public updateTripQuoteRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateTripQuoteRequested),
      mergeMap(({ request }) => {
        return this.api.updateCharterTripQuote(request).pipe(
          mergeMap(() => {
            return this.api.getCharterTripInvoice({ tripId: request.tripId }).pipe(
              map((resp) => updateTripQuoteSuccess({ tripId: request.tripId, invoice: resp })),
              catchError((originalError) => {
                const error: WpError = {
                  originalError,
                  text: 'Failed to load trip invoice',
                };
                return of(updateTripQuoteFailed({ tripId: request.tripId, error }));
              }),
            );
          }),
          catchError((originalError) => {
            const error: WpError = {
              originalError,
              text: 'Failed to update trip quote',
            };
            return of(updateTripQuoteFailed({ tripId: request.tripId, error }));
          }),
        );
      }),
    );
  });

  public updateTripQuoteSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(updateTripQuoteSuccess),
        tap(() => {
          this.snackbar.success('Trip price updated successfully');
        }),
      );
    },
    { dispatch: false },
  );

  public updateTripDetailsRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateCharterTripDetailsRequested),
      mergeMap(({ request }) => {
        return this.api.updateCharterTrip(request).pipe(
          mergeMap(() => {
            return this.api.getCharterTrip({ tripId: request.tripId }).pipe(
              map((trip) => {
                return updateCharterTripDetailsSuccess({ trip });
              }),
            );
          }),
          catchError((originalError) => {
            const error: WpError = {
              originalError,
              text: 'Failed to update trip information',
            };
            return of(updateCharterTripDetailsFailed({ error }));
          }),
        );
      }),
    );
  });

  public updateTripDetailsSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(updateCharterTripDetailsSuccess),
        tap((trip) => {
          this.tripDetailsRouteService.navigate({ tripId: trip.trip.tripId });
          this.snackbar.success('Trip information updated successfully');
        }),
      );
    },
    { dispatch: false },
  );

  public onWriteError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(updateTripQuoteFailed, updateCharterTripDetailsFailed),
        tap(({ error }) => {
          this.popup.openErrorPopup(error);
        }),
      );
    },
    { dispatch: false },
  );

  private async cancelTrip(tripId: string, cancelDetails: CancelTripFormValue): Promise<GetCharterTripResponse> {
    const estimation = await firstValueFrom(this.api.getCharterTripCancellationEstimate({ tripId }));
    const request: CancelCharterTripRequest = {
      ...cancelDetails,
      tripId,
      refundCents: estimation.refundCents,
    };
    await firstValueFrom(this.api.cancelCharterTrip(request));
    return await firstValueFrom(this.api.getCharterTrip({ tripId }));
  }
}
