import { Injectable } from '@angular/core';
import { SessionStorageService } from '../../core/services';
import { TripEditorQuoteState } from './store/trip-editor-quote/types';

@Injectable({
  providedIn: 'root',
})
export class TripEditorQuoteStorageService {
  private storageKey = 'tripEditorQuote';

  constructor(private storage: SessionStorageService) {}

  public getState(): TripEditorQuoteState | null {
    return this.storage.get(this.storageKey, true);
  }

  public setState(state: TripEditorQuoteState): void {
    this.storage.set(this.storageKey, state);
  }

  public removeState(): void {
    this.storage.remove(this.storageKey);
  }

  public hasStateForTripItinerary(tripItineraryId: string): boolean {
    const state = this.getState();
    return state?.tripItineraryId === tripItineraryId;
  }
}
