import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import {
  initTripEditorReservationPayment,
  initTripEditorReservationPaymentFromContract,
} from './trip-editor-payment.actions';
import { selectTripEditorContract } from '../trip-editor-contract/trip-editor-contract.selectors';
import { concatLatestFrom } from '@ngrx/operators';

@Injectable()
export class TripEditorPaymentEffects {
  public initStateFromContract$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(initTripEditorReservationPayment),
      concatLatestFrom(() => this.store.select(selectTripEditorContract)),
      map(([{ isEditTripMode }, contract]) => {
        return initTripEditorReservationPaymentFromContract({ contract, isEditTripMode });
      }),
    );
  });

  constructor(
    private store: Store,
    private actions$: Actions,
  ) {}
}
