import { createReducer, on } from '@ngrx/store';
import * as fromActions from './trip-editor-contract.actions';
import { GetCharterContractResponse } from '@rootTypes/api';
import { WpError } from '@rootTypes';
import { createNewTripSuccess, initializeNewTrip } from '../create-trip/create-trip.actions';
import { clearStore } from '../../../../store/common/clear-store.actions';
import { editTripRootDestroy, loadEditTripDataSuccess, openEditTripFlow } from '../edit-trip/edit-trip.actions';

export interface TripEditorContractState {
  isLoading: boolean;
  data?: GetCharterContractResponse;
  error?: WpError;
}

const createInitialState = (): TripEditorContractState => {
  return { isLoading: false };
};

export const tripEditorContractReducer = createReducer(
  createInitialState(),
  on(initializeNewTrip, createNewTripSuccess, openEditTripFlow, editTripRootDestroy, clearStore, createInitialState),
  on(fromActions.tripEditorGetContractRequested, (): TripEditorContractState => {
    return { isLoading: true };
  }),
  on(fromActions.tripEditorGetContractSuccess, (state, { response }): TripEditorContractState => {
    return {
      isLoading: false,
      data: response,
    };
  }),
  on(fromActions.tripEditorGetContractFailed, (state, { error }): TripEditorContractState => {
    return {
      isLoading: false,
      error,
    };
  }),
  on(loadEditTripDataSuccess, (state, { data }): TripEditorContractState => {
    return {
      isLoading: false,
      data: data.charterContract,
    };
  }),
);
