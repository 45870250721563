import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { homeInitialized, homeDestroyed } from '../../../store/auth/auth.actions';
import { Observable } from 'rxjs';
import { HomeHeaderService } from '../../services/home-header.service';

@Component({
  selector: 'c-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit, OnDestroy {
  public homeMarginTop$: Observable<string>;

  constructor(
    private store: Store,
    private homeHeaderService: HomeHeaderService,
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(homeInitialized());
    this.homeMarginTop$ = this.homeHeaderService.marginTopPx;
  }

  public ngOnDestroy(): void {
    this.store.dispatch(homeDestroyed());
  }

  public getContentHeight(): string {
    return this.homeHeaderService.getContentHeight();
  }
}
