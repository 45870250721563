import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
  createTripPath,
  homeRoute,
  loginRoute,
  tripsPath,
  noPermissionsRoute,
  appConfigErrorRoute,
  unauthorizedRoute,
} from './routes';
import { HomeComponent } from './core/containers/home/home.component';
import { tripDetailsPath } from './routes/trip-details/trip-details-path';
import { GoogleInitGuardService } from './core/guards/google-init-guard.service';
import { HomeGuard } from './core/guards/home.guard';
import { TripEditorGuard } from './core/guards/trip-editor.guard';
import { TripsViewGuard } from './core/guards/trips-view.guard';
import { NoPermissionsComponent } from './core/containers/no-permissions/no-permissions.component';
import { AppConfigErrorComponent } from './core/containers/app-config-error/app-config-error.component';
import { UnauthorizedComponent } from './core/containers/unauthorized/unauthorized.component';
import { LoginGuard } from './core/guards/login.guard';
import { LoginComponent } from './core/containers/login/login.component';
import { editTripModulePath } from './routes/edit-trip/edit-trip-module-path';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: homeRoute.getHref(),
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [HomeGuard, GoogleInitGuardService],
    children: [
      {
        path: createTripPath,
        canActivate: [TripEditorGuard],
        loadChildren: () => import('./features/create-trip/create-trip.module').then((m) => m.CreateTripModule),
      },
      {
        path: editTripModulePath,
        canActivate: [TripEditorGuard],
        loadChildren: () => import('./features/edit-trip/edit-trip.module').then((m) => m.EditTripModule),
      },
      {
        path: tripsPath,
        canActivate: [TripsViewGuard],
        loadChildren: () => import('./features/trips/trips.module').then((m) => m.TripsModule),
      },
      {
        path: tripDetailsPath,
        canActivate: [TripsViewGuard],
        loadChildren: () => import('./features/trip-details/trip-details.module').then((m) => m.TripDetailsModule),
      },
    ],
  },
  {
    path: loginRoute.path,
    component: LoginComponent,
    canActivate: [LoginGuard],
  },
  {
    path: unauthorizedRoute.path,
    component: UnauthorizedComponent,
  },
  {
    path: noPermissionsRoute.path,
    component: NoPermissionsComponent,
  },
  {
    path: appConfigErrorRoute.path,
    component: AppConfigErrorComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
