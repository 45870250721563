import { createReducer, on } from '@ngrx/store';
import * as createTripActions from '../create-trip/create-trip.actions';
import { clearStore } from '../../../../store/common/clear-store.actions';
import { validateTripItinerarySuccess } from '../trip-editor-stops/trip-editor-stops.actions';
import { editTripRootDestroy, loadEditTripDataSuccess, openEditTripFlow } from '../edit-trip/edit-trip.actions';

export interface TripEditorIdsState {
  tripItineraryId: string; // existing or new trip itinerary id
  tripId?: string;
}

const createInitialState = (): TripEditorIdsState => {
  return {
    tripItineraryId: null,
  };
};

export const tripEditorIdsReducer = createReducer(
  createInitialState(),
  on(
    createTripActions.initializeNewTrip,
    createTripActions.createNewTripSuccess,
    openEditTripFlow,
    editTripRootDestroy,
    clearStore,
    createInitialState,
  ),
  on(
    createTripActions.initializeNewTripVehicles,
    createTripActions.initializeNewTripReview,
    createTripActions.initializeNewTripReservation,
    (state, { tripItineraryId }): TripEditorIdsState => {
      return {
        tripItineraryId,
      };
    },
  ),
  on(validateTripItinerarySuccess, (state, { response }): TripEditorIdsState => {
    return {
      ...state,
      tripItineraryId: response.tripItineraryId,
    };
  }),
  on(loadEditTripDataSuccess, (state, { data }): TripEditorIdsState => {
    const { charterTrip, newTripItinerary } = data;
    return {
      tripItineraryId: newTripItinerary.tripItineraryId,
      tripId: charterTrip.tripId,
    };
  }),
);
