import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as fromActions from './trip-editor-contract.actions';
import { ApiService } from 'src/app/core/services';

import { tripEditorGetTripItinerarySuccess } from '../trip-editor-itinerary/trip-editor-itinerary.actions';

@Injectable()
export class TripEditorContractEffects {
  public loadContractTrigger$ = createEffect(() => {
    return this.actions$.pipe(ofType(tripEditorGetTripItinerarySuccess)).pipe(
      map(({ response }) => {
        return fromActions.tripEditorGetContractRequested({ contractId: response.contractId });
      }),
    );
  });

  public loadContractRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.tripEditorGetContractRequested),
      switchMap(({ contractId }) => {
        return this.api.getCharterContract({ contractId }).pipe(
          map((response) => {
            return fromActions.tripEditorGetContractSuccess({ response });
          }),
          catchError((originalError) => {
            return of(
              fromActions.tripEditorGetContractFailed({
                error: {
                  text: 'Failed to load charter contract',
                  originalError,
                },
              }),
            );
          }),
        );
      }),
    );
  });

  constructor(
    private api: ApiService,
    private actions$: Actions,
  ) {}
}
