import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HomeHeaderService {
  public marginTopPx = new BehaviorSubject<string>('0px');
  public $homeHeaderHeight = new BehaviorSubject<string>('72px');

  setMarginTopPx(value: string): void {
    this.marginTopPx.next(value);
  }

  resetMarginTopPx(): void {
    this.marginTopPx.next('0px');
  }

  getContentHeight(): string {
    return `calc(100vh - ${this.$homeHeaderHeight.value} - ${this.marginTopPx.value} - 1px)`;
  }
}
