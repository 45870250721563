import { createAction, props } from '@ngrx/store';
import { PaymentMethod, PaymentOption } from '@rootTypes';
import { GetCharterContractResponse } from '@rootTypes/api';

const paymentComponent = '[TripEditorReservationPayment]:';

export const initTripEditorReservationPayment = createAction(
  `${paymentComponent} Initialize`,
  props<{ isEditTripMode: boolean }>(),
);

export const destroyTripEditorReservationPayment = createAction(`${paymentComponent} Destroy`);

export const tripEditorReservationPaymentMethodChanged = createAction(
  `${paymentComponent} Payment method changed`,
  props<{ paymentMethod: PaymentMethod }>(),
);
export const tripEditorReservationPaymentOptionChanged = createAction(
  `${paymentComponent} Payment option changed`,
  props<{ paymentOption: PaymentOption }>(),
);

export const initTripEditorReservationPaymentFromContract = createAction(
  '[TripEditorPaymentEffects] Initialize payment state from contract',
  props<{ contract: GetCharterContractResponse; isEditTripMode: boolean }>(),
);
